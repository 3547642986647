var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-company-list-table"
  }, [_c('authorization-guard', {
    attrs: {
      "permission": "crm.company.index"
    }
  }, [_c('vs-table', {
    attrs: {
      "showActionButton": "",
      "mode": "remote",
      "columns": _vm.columns,
      "rows": _vm.getter,
      "totalRecords": _vm.table.totalRecords,
      "current-page": _vm.table.page,
      "checkSort": function () {},
      "perPage": _vm.table.perPage,
      "searchTerm": _vm.searchTerm,
      "actionItems": _vm.actionItems,
      "isLoading": _vm.table.isLoading
    },
    on: {
      "on-row-click": _vm.openDetails,
      "searchTermChange": function (term) {
        return _vm.updateSearchTerm(term);
      },
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'name' ? _c('span', [_vm._v(" " + _vm._s(row.name) + " ")]) : _vm._e(), column.field === 'company_status_id' ? _c('span', [_c('status-badge', {
          attrs: {
            "status_id": row.company_status_id
          }
        })], 1) : _vm._e(), column.field === 'email' ? _c('span', [row.contact && row.contact.email ? [_vm._v(" " + _vm._s(row.contact.email) + " ")] : [_vm._v("-")]], 2) : _vm._e(), column.field === 'phone_number' ? _c('span', [row.contact && row.contact.phone_number ? [_vm._v(" " + _vm._s(row.contact.phone_number) + " ")] : [_vm._v("-")]], 2) : _vm._e(), column.field === 'branch_id' ? _c('span', [row.regions.length > 0 ? [_vm._v(" " + _vm._s(row.regions.map(function (region) {
          return region.shortcut;
        }).join(', ')) + " ")] : [_vm._v("-")]], 2) : _vm._e(), column.field === 'industry_id' ? _c('span', [row.industries.length > 0 ? [_vm._v(" " + _vm._s(row.industries.map(function (industry) {
          return industry.name;
        }).join(', ')) + " ")] : [_vm._v("-")]], 2) : _vm._e(), column.field === 'branch_id' ? _c('span', [_c('branch-label', {
          attrs: {
            "id": row.company_branch_id
          }
        })], 1) : _vm._e()];
      }
    }])
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }