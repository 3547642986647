<template>
  <div class="crm-clients-list">
    <b-card>
      <client-list-filtration />

      <div class="buttons">
        <div class="group">
          <vs-button
            v-b-modal.modal-crm-company-add
            vs-variant="primary"
            v-can:store="'crm.company'"
          >
            + {{ $t('crm.clients.button.add') }}
          </vs-button>
        </div>
      </div>
      <br>

      <company-list-table
        company-type="clients"
        detailsRouteName="crm.clients.details"
      />

      <company-modal-add type="client" :table="CRMClients" />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue';

import VsButton from '@/components/ui/vs-button/VsButton.vue';
import ClientListFiltration from '@/components/views/crm/clients/ClientListFiltration.vue';
import CompanyListTable from '@/components/views/crm/company/CompanyListTable.vue';
import CompanyModalAdd from '@/components/views/crm/company/modals/CompanyModalAdd.vue';
import { CRMClients } from '@/hooks/tables';

export default {
  components: {
    BCard,

    VsButton,
    ClientListFiltration,
    CompanyListTable,
    CompanyModalAdd,
  },
  setup() {
    return { CRMClients };
  },
};
</script>
