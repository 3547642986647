<template>
  <div class="add-modal">
    <b-modal
      id="modal-crm-company-add"
      :title="$t('crm.company.add.title')"
      cancel-variant="outline-secondary"
      :cancel-title="$t('crm.company.add.button.cancel')"
      :ok-title="$t('crm.company.add.button.save')"
      size="lg"
      v-model="modalState.add"
      @ok="createCompany"
      @hidden="resetState"
      no-close-on-backdrop
    >
      <vs-loader full
        text="Dodawanie firmy..."
        v-if="isLoading"
      />

      <company-form
        v-model="form"
        :feedback="errors"
      />

      <template #modal-footer="{ ok, cancel }">
        <vs-modal-footer
          :is-loading="isLoading"

          :text-cancel="$t('crm.company.add.button.cancel')"
          :text-ok="$t('crm.company.add.button.save')"

          @ok="ok"
          @cancel="cancel"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import CompanyForm from '@/components/views/crm/company/forms/CompanyForm.vue';
import useCompanyManagement from '@/hooks/crm/useCompanyManagement';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';

export default {
  name: 'CompanyModalAdd',
  props: {
    type: {
      type: String,
    },
    table: {
      type: Object,
    },
  },
  setup(props) {
    const {
      form,
      errors,
      modalState,
      isLoading,

      createCompany,
      resetState,
    } = useCompanyManagement(props.type, props.table);

    return {
      form,
      errors,
      modalState,

      createCompany,
      resetState,
      isLoading,
    };
  },
  components: {
    CompanyForm,
    VsModalFooter,
    VsLoader,
  },
};
</script>

<style scoped>

</style>
