import i18n from '@/libs/i18n';
import { ref } from 'vue';
import createTable from './createTable';

/**
 * Pseudo-store for tables used in app.
 * Moving to CompositionAPI based store for tables
 * to keep Vuex store clean
 */

export const tasks = ref(createTable('Tasks'));
export const orders = ref(createTable('Orders'));
export const cadre = ref(createTable('cadre/employees', [
  {
    label: i18n.t('cadre.list.field.employee'),
    field: 'surname',
    showInDetails: true,
  },
  {
    label: i18n.t('cadre.list.field.group'),
    field: 'group_id',
  },
  {
    label: i18n.t('cadre.list.field.position'),
    field: 'position_id',
    showInDetails: true,
  },
  {
    label: i18n.t('cadre.list.field.branch'),
    field: 'branch_id',
  },
  {
    label: i18n.t('cadre.list.field.status'),
    field: 'status_id',
  },
  {
    label: i18n.t('cadre.list.field.contractEnd'),
    field: 'contract_end',
  },
  {
    label: i18n.t('cadre.list.field.medicalExams'),
    field: 'medical_exams',
  },
], {
  filters: {
    groups_id: [],
    positions_id: [],
    branches_id: [],
    with_trashed: null,
    skills_id: [],
    status_id: [],
    supervisor_id: [],
  },
  sortable: ['surname', 'contract_end'],
  invertedSortable: ['contract_end'],
}));
const CRMColumns = [
  {
    label: i18n.t('crm.companies.field.name'),
    field: 'name',
    showInDetails: true,
  },
  {
    label: i18n.t('global.field.phone'),
    field: 'phone_number',
  },
  {
    label: i18n.t('global.field.email'),
    field: 'email',
  },
  {
    label: i18n.t('crm.companies.field.region'),
    field: 'branch_id',
    showInDetails: true,
  },
  {
    label: i18n.t('crm.companies.field.status'),
    field: 'company_status_id',
  },
  {
    label: i18n.t('crm.companies.field.industry'),
    field: 'industry_id',
  },
];

// TODO: wydzielić do osobnych plików jak w bazie
export const CRMProviders = ref(createTable('crm/providers', CRMColumns, {
  filters: {
    industry_ids: [],
    status_ids: [],
    branch_ids: [],
  },
}));
export const CRMClients = ref(createTable('crm/clients', CRMColumns, {
  filters: {
    industry_ids: [],
    status_ids: [],
    branch_ids: [],
  },
}));
