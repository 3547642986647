<template>
  <div class="crm-company-list-table">
    <authorization-guard
      permission="crm.company.index"
    >
      <vs-table showActionButton
        mode="remote"
        :columns="columns"
        :rows="getter"
        :totalRecords="table.totalRecords"
        :current-page="table.page"
        :checkSort="() => {}"
        :perPage="table.perPage"
        :searchTerm="searchTerm"
        :actionItems="actionItems"
        :isLoading="table.isLoading"
        @on-row-click="openDetails"
        @searchTermChange="(term) => updateSearchTerm(term)"
        @on-page-change="onPageChange"
        @on-per-page-change="onPageChange"
        >
        <template v-slot:row="{ column, row }" >

          <span v-if="column.field === 'name'">
            {{ row.name}}
          </span>

          <span v-if="column.field === 'company_status_id'">
            <status-badge :status_id="row.company_status_id" />
          </span>

          <span v-if="column.field === 'email'">
            <template v-if="row.contact && row.contact.email">
              {{ row.contact.email }}
            </template>
            <template v-else>-</template>
          </span>

          <span v-if="column.field === 'phone_number'">
            <template v-if="row.contact && row.contact.phone_number">
              {{ row.contact.phone_number }}
            </template>
            <template v-else>-</template>
          </span>

          <span v-if="column.field === 'branch_id'">
            <template v-if="row.regions.length > 0">
              {{ row.regions.map((region) => region.shortcut).join(', ') }}
            </template>
            <template v-else>-</template>
          </span>

          <span v-if="column.field === 'industry_id'">
            <template v-if="row.industries.length > 0">
              {{ row.industries.map((industry) => industry.name).join(', ') }}
            </template>
            <template v-else>-</template>
          </span>

          <span v-if="column.field === 'branch_id'">
            <branch-label :id="row.company_branch_id" />
          </span>

        </template>
      </vs-table>
    </authorization-guard>
  </div>
</template>

<script>
import router from '@/router';
import store from '@/store';

import BranchLabel from '@/components/ui/BranchLabel.vue';
import StatusBadge from '@/components/ui/status-badge/Company.vue';
import VsTable from '@/components/ui/vs-table/Table.vue';
import { CRMProviders, CRMClients } from '@/hooks/tables';
import { computed } from 'vue';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';

export default {
  props: {
    companyType: {
      type: String,
      default: '',
    },
    detailsRouteName: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const tableHook = props.companyType === 'providers' ? CRMProviders : CRMClients;

    const getter = computed(() => {
      if (props.companyType === 'providers') return store.state.crm.providers.providers;
      return store.state.crm.clients.clients;
    });

    const {
      table, updateSearchTerm, searchTerm, columns, fetchData,
      onPageChange, rows,
    } = tableHook.value;

    fetchData();

    const actionItems = [
      {
        title: 'Edytuj',
        icon: 'Edit2',
        callback: (obj) => {
          router.push({ name: `crm.${props.companyType}.settings`, params: { id: obj.id } });
        },
      },
    ];

    return {
      table,
      getter,
      rows,
      updateSearchTerm,
      searchTerm,
      columns,
      onPageChange,
      actionItems,
    };
  },
  components: {
    VsTable,
    BranchLabel,
    StatusBadge,
    AuthorizationGuard,
  },
  methods: {
    openDetails(data) {
      this.$router.push({ name: this.detailsRouteName, params: { id: data.row.id } });
    },
  },
};
</script>
