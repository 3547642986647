var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('authorization-guard', {
    attrs: {
      "permission": "crm.company.index",
      "silent": ""
    }
  }, [_c('div', {
    staticClass: "card shadow-none bg-transparent border-secondary"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h4', {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.title.filtering')) + " ")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('crm.companies.field.region')
    }
  }, [_c('core-branch-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.filters.branch_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "branch_ids", $$v);
      },
      expression: "filters.branch_ids"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('crm.companies.field.status')
    }
  }, [_c('crm-status-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.filters.status_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "status_ids", $$v);
      },
      expression: "filters.status_ids"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('crm.companies.field.industry')
    }
  }, [_c('crm-industry-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.filters.industry_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "industry_ids", $$v);
      },
      expression: "filters.industry_ids"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('crm.companies.field.services')
    }
  }, [_c('crm-service-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.filters.service_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "service_ids", $$v);
      },
      expression: "filters.service_ids"
    }
  })], 1)], 1)], 1), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.clearAllFilters
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.table.clearAllFilters')) + " ")])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }